<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import type { ProductionTime } from '~/types/analytic';

const { t } = useI18n();

// Stores
const siteService = siteStore();

// Data
const selectedSite = ref(globalStore().getSelectedSite[0]);
const modal = ref(false);

// Form
const schema = yup.object().shape({
  productionTimes: yup.array().of(
    yup.object().shape({
      day: yup.string().required(),
      start: yup.object().shape({
        hours: yup.number().required(),
        minutes: yup.number().required(),
      }),
      end: yup.object().shape({
        hours: yup.number().required(),
        minutes: yup.number().required(),
      }),
    }),
  ),
});
const { handleSubmit, values, setValues } = useForm({
  validationSchema: schema,
  keepValuesOnUnmount: true,
});

// Computed
const getProductionTimes = computed(() => {
  return siteService.getSite(selectedSite.value).configs?.production_times ?? [];
});

const getLastDay = computed(() => {
  const currentDay = values.productionTimes[values.productionTimes.length - 1].day;
  const productionDay = daysAsOptions.value.map((day) => day.key).indexOf(currentDay);
  if (productionDay === daysAsOptions.value.length - 1) return daysAsOptions.value[0].key;
  else return daysAsOptions.value[productionDay + 1].key;
});

const daysAsOptions = computed(() => {
  return [
    { label: t('days.monday'), key: 'monday' },
    { label: t('days.tuesday'), key: 'tuesday' },
    { label: t('days.wednesday'), key: 'wednesday' },
    { label: t('days.thursday'), key: 'thursday' },
    { label: t('days.friday'), key: 'friday' },
    { label: t('days.saturday'), key: 'saturday' },
    { label: t('days.sunday'), key: 'sunday' },
  ];
});

// Methods
const addProductionTime = () => {
  if (!values.productionTimes?.length) {
    setValues({
      productionTimes: [
        {
          id: null,
          day: daysAsOptions.value[0].key,
          start: {
            hours: 0,
            minutes: 0,
          },
          end: {
            hours: 0,
            minutes: 0,
          },
        },
      ],
    });
  } else {
    setValues({
      productionTimes: [
        ...values.productionTimes,
        {
          id: null,
          day: getLastDay.value,
          start: {
            hours: values.productionTimes[values.productionTimes.length - 1].start.hours,
            minutes: values.productionTimes[values.productionTimes.length - 1].start.minutes,
          },
          end: {
            hours: values.productionTimes[values.productionTimes.length - 1].end.hours,
            minutes: values.productionTimes[values.productionTimes.length - 1].end.minutes,
          },
        },
      ],
    });
  }
};

const deleteProductionTime = (index: number) => {
  setValues({
    productionTimes: values.productionTimes.filter((_, i) => i !== index),
  });
};

const sortProductionTimes = (productionTimes: ProductionTime[]) => {
  return productionTimes.sort((a: ProductionTime, b: ProductionTime) => {
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const aDayIndex = days.indexOf(a.day);
    const bDayIndex = days.indexOf(b.day);
    if (aDayIndex < bDayIndex) return -1;
    if (aDayIndex > bDayIndex) return 1;
    if (a.start < b.start) return -1;
    if (a.start > b.start) return 1;
  });
};

const saveProductionTimes = handleSubmit(async () => {
  const site = siteStore().getSite(selectedSite.value);

  await siteStore().updateProductionHours({
    ...site,
    production_hours: { production_times: values.productionTimes },
  });
  await loadData();
  modal.value = false;
  toastStore().showToast({
    title: t('global.success'),
    message: t('global.production_times_updated'),
    type: 'success',
  });
});

const loadData = () => {
  if (selectedSite.value) {
    let site = null;
    if (selectedSite.value) {
      site = siteService.getSites[0];
    } else {
      site = siteService.getSite(selectedSite.value);
    }
    const sortedProductionTimes = sortProductionTimes(site.configs?.production_times);
    setValues({
      productionTimes: sortedProductionTimes.map((pt) => ({
        day: pt.day,
        start: {
          hours: pt.start.hours,
          minutes: pt.start.minutes,
        },
        end: {
          hours: pt.end.hours,
          minutes: pt.end.minutes,
        },
      })),
    });
  }
};

watch(
  () => siteService.getSite(selectedSite.value)?.configs?.production_times,
  () => {
    const site = siteService.getSite(selectedSite.value);
    setValues({
      productionTimes: site?.configs?.production_times.map((pt) => ({
        id: pt.id,
        day: pt.day,
        start: {
          hours: pt.start.hours,
          minutes: pt.start.minutes,
        },
        end: {
          hours: pt.end.hours,
          minutes: pt.end.minutes,
        },
      })),
    });
  },
  { immediate: true },
);

watch(
  () => selectedSite.value,
  () => loadData(),
  { immediate: true },
);
</script>

<template>
  <div>
    <ui-card :title="t('global.production times')">
      <template #header>
        <ui-dropdown v-model="selectedSite" :items="siteService.getSitesAsOptions" @change="loadData" />
        <ui-button left-icon-class="!w-4" left-icon="Pencil" @click="modal = true">
          {{ $t('production_times.update_production_times') }}
        </ui-button>
      </template>
      <div v-if="getProductionTimes.length === 0" class="flex flex-1 pl-2 text-gray-400">
        {{ $t('production_times.no_production_times_text') }}
      </div>
      <div v-else class="flex flex-col flex-1 w-full gap-4">
        <div v-for="pt in getProductionTimes" :key="pt.id" class="flex gap-6 px-6 py-4 border border-[#D9D9D9] rounded-md">
          <h2 class="text-[#374151] text-xl">
            {{ $t(`days.${pt.day}`) }}
          </h2>

          <div class="border py-1 px-2 text-[#374151] text-sm">
            {{ `${pt.start?.hours}h${pt.start?.minutes}` }} -
            {{ `${pt.end?.hours}h${pt.end?.minutes}` }}
          </div>
        </div>
      </div>
    </ui-card>

    <ui-modal v-model="modal" width="700" :title="$t('production_times.update_production_times')">
      <div class="flex flex-col gap-2 md:gap-4 text-primary">
        <p class="text-sm text-start text-gray-400">
          {{ $t('production_times.update_production_times_description') }}
        </p>

        <div class="flex flex-col gap-2">
          <div v-for="(pt, index) in values.productionTimes" :key="index" class="flex items-center gap-2">
            <ui-form-input-select
              :name="`productionTimes[${index}].day`"
              class="w-32 md:w-40 flex-shrink-0"
              :items="daysAsOptions"
              hide-details
            />

            <p class="flex justify-center w-6 text-gray-400 text-sm whitespace-nowrap">
              {{ ':' }}
            </p>

            <div class="flex items-center gap-1 py-1 px-2 border border-[#E6E6E5] rounded">
              <ui-icon name="Clock3" class="text-gray-400 mr-1 w-10" />

              <ui-form-input-text :name="`productionTimes[${index}].start.hours`" type="number" class="w-[60px]" hide-details />
              <span>h</span>
              <ui-form-input-text :name="`productionTimes[${index}].start.minutes`" type="number" class="w-[60px]" hide-details />
            </div>

            <ui-icon name="ArrowRight" class="flex-shrink-0 text-gray-400" />

            <div class="flex items-center gap-1 py-1 px-2 border border-[#E6E6E5] rounded">
              <ui-icon name="Clock3" class="text-gray-400 mr-1 w-10" />
              <ui-form-input-text :name="`productionTimes[${index}].end.hours`" type="number" class="w-[60px]" hide-details />
              <span>h</span>
              <ui-form-input-text :name="`productionTimes[${index}].end.minutes`" type="number" class="w-[60px]" hide-details />
            </div>

            <ui-button color="secondary" left-icon="Trash" @click="deleteProductionTime(index)" />
          </div>

          <ui-button color="secondary" size="lg" left-icon="Plus" @click="addProductionTime()" />
        </div>
      </div>
      <template #footer>
        <ui-button color="secondary" @click="modal = false">
          {{ $t('global.cancel') }}
        </ui-button>
        <ui-button @click="saveProductionTimes">
          {{ $t('global.save') }}
        </ui-button>
      </template>
    </ui-modal>
  </div>
</template>
